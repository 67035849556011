<template>
    <div class="operating_terms_div">
        <div class="nav_div"></div>
        <div class="operating_terms_box">
            <p class="title_p">标准运营条款</p>
            <div class="time_div">
                <span class="time_span">2023年10月23日生效</span>
            </div>
            <div class="paragraph_div">
                <p>1. 在本標準營運條款（以下簡稱“本標準條款”）中，下列詞彙具有以下含義：</p>
                <p>“公司”指上海東志國際貨物運輸代理有限公司。</p>
                <p>“服務”指公司爲客戶提供的所有服務，例如貨物的儲存、裝載、卸載、包裝、打開包裝、標記、配送、裝櫃、拆櫃、拼櫃、拆轉櫃、收取、交付和/或其他處理、和/或空運、海運、內河運輸、陸運、鐵路運輸和/或公路運輸。</p>
                <p>“客戶”指與公司有服務合同的任何個人、機構、公司或法團。</p>
                <p>“貨物”包括貨物、貨品、用品、商品和各種物品（包括非由公司或代表公司提供的運輸工具，例如貨櫃）。</p>
                <p>“危險貨物”指具有危險性、爆炸性、易燃性、放射性和/或破壞性的貨物。具有破壞性的貨物包括可能助長害蟲的貨物。</p>
                <p>“《海牙規則》”指1924年8月25日在布魯塞爾簽署的《統一提單若干法律規則國際公約》。</p>
                <p>“SDR”指國際貨幣基金組織界定的特別提款權。</p>
                <p>2.1. 公司的所有業務均根據本標準條款進行。本標準條款納入公司與客戶之間的任何合同。</p>
                <p>2.2. 如果本標準條款的任何條款在任何時候無效或不合法，剩餘條款的有效性或合法性不受任何影響。</p>
                <p>2.3. 公司可簽發自己的倉單和/或將公司指定爲承運人的運輸合同，例如運單、提單。如果簽發的前述文件之條款與本標準條款存有不一致之處，應以前述文件之條款爲準。</p>
                <p>2.4. 在公司被認定爲承運人的情況下，公司應享有任何適用法律或法例賦予承運人的所有權利、豁免權、責任免除和責任限制。</p>
                <p>2.5. 就海上或內河貨物運輸而言，如果公司被認定爲承運人，公司的責任應根據《海牙規則》第三條和第四條確定。而上述《海牙規則》條款若與本標準條款不一致的，應以上述《海牙規則》條款爲準。《海牙規則》第四條第（5）款中的賠償責任限額應被視爲100英鎊（英國法定貨幣）面值。</p>
                <p>2.6. 在本標準條款中，單數詞語包括複數含義，反之亦然；表示某一性別的詞語包括每一性別。</p>
                <p>2.7. 如果本標準條款中規定公司向客戶發出通知，所述通知在下列情形下應被視爲已經發出：(i)公司不知曉客戶的地址、電子郵箱地址或傳真號碼、或(ii)所述通知無法由公司通過其已知的地址、電子郵箱地址或傳真號碼送達客戶。</p>
                <p>3. 客戶保證其完全遵守任何港口、機場、海關和其他當局的適用法律、法規和條例。</p>
                <p>4. 客戶進一步保證(i)貨物包裝、標籤及標記正確及充分、和(ii)貨物適合運輸、儲存及作其他處理。</p>
                <p>5.1. 客戶應就由於下列原因而起或與之相關的任何索賠、責任、損失、損害、費用和開支（包括任何飛機、貨櫃和船舶的滅失和損壞）向公司作出賠償：(i)公司按照客戶指示行事、(ii)客戶違反保證或義務、(iii)客戶提供的信息不準確或不充分、和/或(iv)客戶、其僱員、代理、承包商或分包商的錯誤、疏忽、故意違約或故意不當行爲。</p>
                <p>5.2. 客戶承諾不對公司的任何僱員、代理或分包商提出與服務相關之索賠。如果仍有所述任何索賠，客戶應賠償公司因此而産生的所有後果。上述每一名僱員、代理和分包商應受惠於本標準條款中對公司有利的所有條款，猶如該等條款明文規定是為其利益而設的。就此而言，公司為其本身以及作為每名上述的僱員、代理和分包商的代理和受託人訂立合約。</p>
                <p>5.3. 無論誰人向公司提起的索賠和要求（包括與公司的疏忽或錯誤相關的所有索賠和要求、及與公司的僱員、代理或分包商的錯誤、疏忽、故意違約或故意不當行爲相關的所有索賠和要求）超過公司按本標準條款所承擔的責任，客戶應向公司因此而遭受的一切損失作出賠償。</p>
                <p>5.4. 客戶應就可能對公司提出的共同海損索賠和/或海難救助索賠爲公司抗辯、向公司賠償、及使公司免受損害，且客戶應按公司要求提供共同海損擔保和/或海難救助擔保。公司有權就共同海損擔保和/或海難救助擔保留置任何貨物。在公司向客戶發出要求客戶提供共同海損擔保和/或海難救助擔保的通知後14天內，如果客戶仍未按公司要求提供共同海損擔保或海難救助擔保，公司有全權自行決定通過公開拍賣或以私人協約方式出售貨物、或處置貨物，風險和費用由客戶承擔，淨收益（如有）應用於支付共同海損分攤款和/或海難救助報酬分攤款。即使客戶沒有收到公司發出的上述通知，亦不影響公司上述出售或處置貨物的權利。客戶應支付公司因共同海損擔保和/或海難救助擔保而留置貨物所產生的任何費用和開支（包括儲存費和滯期費）。</p>
                <p>6. 客戶保證貨物並非危險貨物。如果客戶將任何危險貨物交付給公司或致使公司需處理任何危險貨物（根據事先以書面形式作出的特殊安排除外），無論公司是否清楚上述危險貨物的性質，客戶均應就所有與上述危險貨物相關的罰款、索賠、損失、損害、費用、開支和責任向公司作出賠償，而公司有全權自行決定銷毀或以其他方式處置上述危險貨物，風險和費用由客戶承擔。如果公司根據事先書面安排處理任何危險貨物，但出於上述危險貨物對其他貨物、財産、生命或健康構成風險的考慮，公司仍有全權自行決定銷毀上述危險貨物，風險和費用由客戶承擔。</p>
                <p>7. 除非事先以書面形式作出特殊安排，否則公司不會處理金塊、銀塊、鈔票、硬幣、支票、債券、流通票據和證券、寶石、貴金屬物品、珠寶、古董、貴重藝術品、活動物或活植物。如果客戶向公司交付上述任何貨物或致使公司需處理上述任何貨物（根據事先以書面形式作出的特殊安排除外），公司不承擔與上述貨物相關的任何責任。</p>
                <p>8.1. 如果客戶未在應提貨的時間和地點提貨，公司有權自行儲存貨物，風險由客戶承擔。公司就上述儲存的貨物可能承擔的任何責任應隨即完全終止，且客戶應向公司支付上述儲存費用和開支（包括滯期費）。</p>
                <p>8.2. 如果客戶在公司向客戶發出要求客戶提貨的通知後14天內未提貨，公司有全權自行決定通過公開拍賣或以私人協約方式出售貨物、或處置貨物，風險和費用由客戶承擔。即使客戶沒有收到公司發出的上述通知，亦不影響公司上述出售或處置未提貨物的權利。客戶應支付與貨物儲存、出售或處置相關的所有費用和開支（包括滯期費）。</p>
                <p>8.3. 所有貨物及與之有關的文件均可(i)因貨物所涉應付款項而被特殊留置及(ii)因客戶應向公司支付的任何其他款項而被一般留置。在公司向客戶發出要求客戶支付未付款項的通知後14天內，如果客戶仍未按公司要求支付任何應向公司支付的款項，公司有全權自行決定通過公開拍賣或以私人協約方式出售貨物、或處置貨物，風險和費用由客戶承擔，淨收益（如有）應用於償還相關債務，客戶不得僅因貨物已被出售或處置而被免除對未償債務的責任。即使客戶沒有收到公司發出的上述通知，亦不影響公司上述出售或處置貨物的權利。因留置貨物而産生的所有費用和開支（包括儲存費用和滯期費）由客戶承擔。</p>
                <p>9.1. 客戶應立即向公司支付所有到期應付款項，不得以任何索賠、反索賠或抵銷爲由扣除所述任何款項。除公司書面同意的特殊安排外，任何發票金額應在向客戶提供發票後立即到期應付。對於自到期日起30天內仍未支付的任何款項，公司有權按每月2%的利率收取利息，利息自發票日期起計至實際付款日止。</p>
                <p>9.2. 如果貨物以運費到付的方式運輸，但收貨人在貨物抵達交貨地點後14天內仍未提貨，客戶應負責支付所有未付運費、及截至貨物被提取或根據第8.2條和/或第8.3條規定被出售或處置爲止産生的費用和開支（包括儲存費用和滯期費）。</p>
                <p>9.3. 對於免費提供的任何服務，公司概不承擔任何不論性質或無論如何引起的責任。</p>
                <p>10.1. 公司有權將全部或部分服務以任何條款分包給任何代理或分包商。</p>
                <p>10.2. 公司可絕對自行決定及自由選擇履行服務的方法、方式、路線和程序，包括使用任何船舶、在甲板上或甲板下積載貨物、轉運。根據上述自行決定權和/或自由選擇權而採取的任何行動均不得被視爲任何性質或程度的繞航。</p>
                <p>11. 就非公司、其僱員、代理或分包商的疏忽或錯誤而引起的事件而言，公司對與前述事件有關的任何索賠無須負責。</p>
                <p>12. 如果由於公司的疏忽或錯誤、或公司的僱員、代理或分包商的疏忽、錯誤、故意違約或故意不當行爲而導致貨物發生任何滅失、損壞、錯誤交付（第13.1條和第13.2條所規定的索賠除外）、未能交付或錯誤運送，公司應對與上述事件有關的索賠負責。但是，公司的上述責任不得超過按照索賠所涉貨物毛重每公斤2 SDR計算的總額。</p>
                <p>13.1. 如果公司的僱員、代理或分包商未經公司批准而疏忽地或故意地在收貨人未交出提單的情況下錯誤交付貨物，公司應對與上述錯誤交付貨物有關的索賠負責。但是，公司的上述責任不得超過按照錯交貨物毛重每公斤2 SDR計算的總額。</p>
                <p>13.2. 如果公司的僱員、代理或分包商未經公司批准而疏忽地或故意地將貨物錯誤交付給無權接收貨物的人，公司應對與上述錯誤交付貨物有關的索賠負責。但是，公司的上述責任不得超過按照錯交貨物毛重每公斤2 SDR計算的總額。</p>
                <p>14. 儘管本標準條款中有任何其他相反的條款，但在遵守第2.3條和第2.5條的前提下，公司對與以下事件有關的任何索賠無須負責：</p>
                <p>a. 延誤、貨物被拒裝船或開船前被卸載、貨物離港或抵港時間、</p>
                <p>b. 附帶、間接或後果性損失（包括市場損失、利潤損失、稅款損失、退稅損失、收入損失、收益損失、業務損失和商譽損失）、或</p>
                <p>c. 因火災、洪水、風暴、颱風、爆炸、戰爭、制裁、港口擁塞、機場擁塞、繞航、罷工、閉廠、停工或勞工限制而起或與之相關的貨物滅失或損壞、費用、開支，</p>
                <p>而無論上述事件是否由公司的疏忽或錯誤、或公司的僱員、代理或分包商的疏忽、錯誤、故意違約或故意不當行爲而引起。但是，儘管有上述規定，如果公司根據法律仍被判決須對上述索賠負責，公司的責任不得超過按照索賠所涉貨物毛重每公斤2 SDR計算的總額。</p>
                <p>15. 如果公司根據法律被判決須對任何索賠（第12條、第13.1條、第13.2條和第14條所規定的索賠除外）負責，公司的責任不得超過75,000 SDR以每次事件或因同一事由而起的一系列事件計。</p>
                <p>16. 公司在第12條、第13.1條、第13.2條和第14條下的責任應進一步以每次事件200,000 SDR爲限，即公司在第12條、第13.1條、第13.2條和第14條下的責任在任何情況下不得超過200,000 SDR以每次事件或因同一事由而起的一系列事件計。</p>
                <p>17. 公司可承擔超過第2.5條、第12條、第13.1條、第13.2條、第14條、第15條和第16條所規定之限額的責任，前提是(i)客戶以書面形式聲明貨物價值或要求更高的限額，且公司以書面形式接受將所述價值或限額作爲新的責任限額、及(ii)客戶向公司支付公司決定的額外費用。額外費用的詳細信息可根據客戶的書面要求而提供。</p>
                <p>18. 除公司根據第2.5條、第12條、第13.1條、第13.2條、第14條、第15條、第16條和第17條規定承擔責任的索賠外，公司對任何其他索賠無須負責。</p>
                <p>19. 任何表面銹蝕、氧化、變色或任何由於水分引起的類似狀況均不構成貨損，而是貨物的自然屬性。公司確認收到表面狀況良好的貨物，概不代表收到貨物時貨物不存在上述銹蝕、氧化、變色或由於水分引起的類似狀況。</p>
                <p>20. 如果服務受到或可能受到任何不論性質、無論何時發生及無論如何引致的風險、延誤、阻礙、困難和/或不利因素（包括客戶拖欠服務費2個月或以上、戰爭、制裁、港口擁塞、機場擁塞、罷工、閉廠、停工及勞工限制）的影響，公司可以終止和/或放棄提供服務、及將貨物放置在任何地方以供客戶提貨，公司隨即不再承擔任何與貨物相關的責任。客戶應支付所有未支付的服務費用，以及截至客戶提貨爲止産生的所有費用和開支（包括儲存費用和滯期費）。如果客戶在向其發出通知後14天內仍未提貨或未支付未償付的服務費、費用或開支，公司有全權自行決定通過公開拍賣或以私人協約方式出售貨物、或處置貨物，風險和費用由客戶承擔，淨收益（如有）應用於支付未償付的服務費、費用和開支。客戶不得僅因貨物已被出售或處置而被免除對未償付的服務費、費用或開支的責任。即使客戶沒有收到公司發出的上述要求客戶提貨及支付未償付的服務費、費用和開支的通知，亦不影響公司上述出售或處置貨物的權利。</p>
                <p>21. 任何索賠通知必須採用書面形式及由客戶在以下日期（以最早者為準）起14天內送達公司：公司向客戶交付貨物之日、或貨物本應交付之日、或客戶首次獲悉導致索賠的事件之日。如果客戶未能在14天內向公司送達上述書面索賠通知，從而損害了公司在索賠案件中的處境或利益，公司在任何情況下均獲解除與索賠有關的全部責任。</p>
                <p>22. 除非在以下日期（以最早者為準）起9個月內向公司提起訴訟，否則公司在任何情況下均獲解除與服務有關的全部不論性質及無論如何引起的責任：公司向客戶交付貨物之日、或貨物本應交付之日、或導致索賠的事件發生之日。</p>
                <p>23. 本標準條款中規定的抗辯、責任免除和限制均適用於針對公司的任何訴訟，無論所述訴訟是基於違約或侵權。 </p>
                <p>24. 與公司簽訂的所有合同以及本標準條款均受中華人民共和國法律管轄。任何索賠或爭議均須由中華人民共和國法院獨家裁定而非任何其他法院裁定。</p>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    computed: {},
    mounted() {},
    watch: {},
    methods: {},
    data() {
        return {
            
        }
    }
}
</script>
<style lang="less">
.operating_terms_div{
    .operating_terms_box{
        line-height: 48px;
        margin: 30px 0;
        .title_p{
            font-size: 20px;
            font-weight: 700;
            text-align: center;
        }
        .time_div{
            text-align: center;
            .time_span{
                font-size: 16px;
                // margin: 0 100px 0 0;
            }
        }
        .paragraph_div{
            width: 80%;
            margin: 0 auto;
            p{
                text-indent:24px
            }
        }
    }
}
</style>
